import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '../components/Card';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import ModalDialog from '../components/dialog'

const Index = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    console.log(open)
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
  <Layout>
    <ModalDialog open={open} onClose={handleClose} />
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Tilavaraus.fi - Varausjärjestelmä
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Helppokäyttöinen ja monipuolinen varausjärjestelmä kaikenlaisten tilojen varaukseen
          </p>
          <p className="mt-8 md:mt-12">
            <Button color="primary" variant="contained" onClick={handleClickOpen} size="large">Tilaa tästä</Button>
          </p>
          <p className="mt-4 text-gray-600">Vain 49€/kk, kun teet 12kk sopimuksen</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="ominaisuudet" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Tärkeimmät ominaisuudet</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Nopea ja helppo käyttöliittymä</p>
              <p className="mt-4">
              Helppo käyttää myös puhelimella ja tabletilla. iCal - tuki, jonka avulla voit viedä, vaikka joukkueen treeniajankohdat omaan kalenteriisi.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Rajaton määrä tiloja</p>
              <p className="mt-4">
                Järjestelmässä ylläpidettävien tilojen määrää ei ole rajoitettu.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Rajaton määrä varauksia ja käyttäjiä</p>
              <p className="mt-4">
                Varauksien tai käyttäjien määrää ei ole rajoitettu. 
              </p>
            </Card>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Tilojen jakaminen lohkoihin</p>
              <p className="mt-4">
                Voit jakaa tilat halutessasi lohkoihin. Lohkoja voi olla periaatteessa rajaton määrä. Voit tehostaa tilojen varaamista, määrittämällä lohkot esimerkiksi jalkapallokentälle.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Kertavaraukset ja toistuvat varaukset</p>
              <p className="mt-4">
                Käyttäjä voi varata joko yksittäisen vuoron tai viikoittain toistuvan vuoron.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Vapaiden tilojen tai vuorojen haku</p>
              <p className="mt-4">
                Käyttäjä voi hakea järjestelmästä vapaita tiloja / vuoroja monipuolisilla rajaustekijöillä.
              </p>
            </Card>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Korkea turvallisuus</p>
              <p className="mt-4">
              SSL-suojaus ja https-yhteys, palvelimet sijaitsevat EU-alueella ja GDPR - säännökset on otettu huomioon.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Tilojen esittelyt</p>
              <p className="mt-4">
                Järjestelmään on mahdollista lisätä yksittäisten tilojen esittelyt.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Monipuoliset ylläpito-ominaisuudet</p>
              <p className="mt-4">
              mm. “Kielletyt ajankohdat” ja arkipyhät, hinnat (tilakohtaiset hinnat, ilmaiset tilat), varaukset hallinta (tarkastelu, muokkaus ja peruminen), vakiovuorojen aikavälin määritys
              </p>
            </Card>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Oma tilavaraus.fi - verkkotunnus</p>
              <p className="mt-4">
                Esimerkiksi https://omatilasi.tilavaraus.fi
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Integrointi muihin järjestelmiin</p>
              <p className="mt-4">
                Voimme toteuttaa tilauksesta integrointeja muihin järjestelmiin lyhyellä varoitusajalla.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Raportointi</p>
              <p className="mt-4">
                Ylläpitäjä voi muodostaa haluamillaan rajausehdoilla raportin esim. laskutusta helpottamaan.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="tietoja"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Paranna tilojesi käyttöastetta</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Helpot varaustoiminnot ja hyvä käytettävyys auttavat parantamaan tilojen käyttöastetta. 
            Järjestelmä tukee myös ilmaisten tilojen varauksia ja tilaesittelyjä. 
            Varausjärjestelmän avulla saat tilat ja tilojen ominaisuudet helposti kaikkien tietoon.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Helppo käyttöönotto ja ylläpito
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Voimme tarvittaessa auttaa siirtymisessä nykyisestä järjestelmästäsi uuteen. Ota rohkeasti yhteyttä ja paranna tilavaraus.fi - järjestelmän avulla palveluiden saatavuutta ja käyttöastetta.
            Tilavaraus.fi on SaaS -palvelu (Software as a Service), joten se ei vaadi asiakkaalta palvelintilaa tai monimutkaista ylläpitoa.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Monipuoliset ominaisuudet
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="hinnat" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Hinnat alkaen (alv 0%)</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="49€/kk" secondaryText="Sopimusjakso 12kk" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="399€" secondaryText="Perustamiskulut, sisältää 4h käyttökoulutuksen." />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="65€/h" secondaryText="Asiakkaan tilaamat lisätyöt" />
          </div>
        </div>
      </div>
    </section>

    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Oletko valmis siirtymään nykyaikaiseen järjestelmään?</h3>
      <p className="mt-8 text-xl font-light">
        
      </p>
      <p className="mt-8">
        <Button color="primary"  variant="contained" onClick={handleClickOpen} size="large">Olen! Tilaan järjestelmän</Button>
      </p>
    </section>
  </Layout>
)
}

export default Index